import React from 'react'
import { graphql } from 'gatsby'
import get from "lodash/get"
import BackgroundImage from "gatsby-background-image"
import Layout from '../components/Layout'
import styled from 'styled-components'
import SEO from "../components/seo"
import ReactMarkdown from "react-markdown"
import PageIntro from "../components/PageIntro"
import useGeneralSettings from "../hooks/use-general-settings"

export const AboutPageTemplate = ({
  about_intro,
  about_content_1,
  about_content_2,
  isPreview = false,
  fluidImage,
  fluidImageB,
  downloadReportUrl
}) => (
  <AboutWrapper>
    <section className="page-header">
      {fluidImageB && (
        <div className="page-header__background">
          <ImageBackground
            Tag="div"
            fluid={fluidImageB}
            style={{ 
              backgroundPosition: "bottom right", 
              backgroundSize: "contain"
            }}
          />
        </div>
      )}
      <div className="container">
        <div className="inner-container">
           <div className="columns">
            <div className="column column-6">
              <PageIntro
                {...about_intro}
                buttons={[
                  {
                    class: "primary",
                    text: "Explore all cities",
                    href: "/#cities"
                  },
                  {
                    class: "",
                    text: "Download Report",
                    href: downloadReportUrl || "/"
                  }
                ]}
              />
            </div>
            <div className="column column-6" style={{marginBottom: 0, position: "relative"}}>
              {fluidImage && (
                <ImageBackground
                  Tag="div"
                  fluid={fluidImage}
                  style={{
                    backgroundPosition: "top left",
                    backgroundSize: "contain"
                  }}
                />
              )}
                
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="about-content">
      <div className="container">
        <div className="inner-container">
          <div className="columns">
            <div className="column column-6 column-a">
              <div className="content">
                <ReactMarkdown source={about_content_1} />
              </div>
            </div>
            <div className="column column-6 column-b">
              <div className="content">
                <ReactMarkdown source={about_content_2} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </AboutWrapper>
)

const About = ({ data: { markdownRemark: { frontmatter }, backgroundImage, backgroundImageB } }) => {
  const fluidImage = get(backgroundImage, "childImageSharp.fluid")
  const fluidImageB = get(backgroundImageB, "childImageSharp.fluid")
  const { download_report_url } = useGeneralSettings()
  return (
    <Layout className="about">
      <SEO title={"About"} />
      <AboutPageTemplate {...frontmatter} fluidImage={fluidImage} fluidImageB={fluidImageB} downloadReportUrl={download_report_url} />
    </Layout>
  )
}

const AboutWrapper = styled.div`
  .page-header {
    position: relative;
    background: rgba(160, 222, 223, 0.08);
    padding: 2.5rem 0;

    @media screen and (min-width: ${({ theme }) => theme.tablet}) {
      padding: 7.5rem 0;
    }

    &__background {
      display: none;
      position: absolute;
      width: 50%;
      height: calc(100% - 5.5rem);
      top: 0;
      left: 50%;
      margin-top: 5.5rem;

      @media (min-width: 1100px) {
        display: block;
      }
    }

    .page-intro {
      p.large {
        font-size: 1.25rem;
        line-height: 1.6;
        letter-spacing: normal;

        @media (min-width: ${({ theme }) => theme.tablet}) {
          font-size: 1.875rem;
          line-height: 1.33;
          letter-spacing: -0.2px;
        }
      }
    }
  }

  .about-content {
    background: ${({ theme }) => theme.greenBlue};;
    border-bottom: 2px solid ${({ theme }) => theme.offWhite};
    padding: 3.5rem 0;

    @media screen and (min-width: ${({ theme }) => theme.tablet}) {
      padding: 7.5rem 0;
    }

    * {
      color: white;
    }

    h1, h2, h3, h4 {
      ${({ theme }) => theme.isHeadingSize['2']}
    }

    @media (min-width: ${({ theme }) => theme.desktop}) {
      .column-a {
        padding-right: 2.5rem;
      }

      .column-b {
        padding-left: 2.5rem;
      }
    }
  }
`

const ImageBackground = styled(BackgroundImage)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  background-repeat: no-repeat;

  @media (min-width: 2179px) {
    background-position: bottom right;
  }
`

export const pageQuery = graphql`
  query {
    markdownRemark(frontmatter: { templateKey: { eq: "about-page" } }) {
      frontmatter {
        title
        about_intro {
          heading
          subtitle
          text
        }
        about_content_1
        about_content_2
      }
    }
    backgroundImage: file(relativePath: { eq: "about-bg-a.png" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 80) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    backgroundImageB: file(relativePath: { eq: "about-bg-b.png" }) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`

export default About
